import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {OperationTypes} from "../../../utills";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'tp-base-operation',
  template: ``,
  styles: []
})
export class BaseOperationComponent implements OnInit, OnDestroy {
    @Input() set operation(operation: keyof typeof OperationTypes){
        this._operation = operation;
        this.operation$.next(operation);
    };
    get operation(): keyof typeof OperationTypes { return this._operation };
    private _operation: keyof typeof OperationTypes = OperationTypes.Add;
    operation$: BehaviorSubject<keyof typeof OperationTypes> = new BehaviorSubject<keyof typeof OperationTypes>(OperationTypes.Add);
    destroyed$: Subject<boolean> = new Subject<boolean>();
    isEditOperation: boolean = false;
    isAddOperation: boolean = false;
    constructor() {
        this.operation$.pipe(takeUntil(this.destroyed$)).subscribe(op => {
           this.isEditOperation = this.isEdit();
           this.isAddOperation = this.isAdd();
        });
    }

    ngOnInit(): void {}
    ngOnDestroy() {
        this.destroyed$.next();
        this.destroyed$.complete();
    }

    isAdd(): boolean{
        return (this.operation === OperationTypes.Add);
    }

    isEdit(): boolean{
        return (this.operation === OperationTypes.Edit);
    }
}