import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild,} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ResponseClientData } from 'app/main/pm/clients/models';
import { ResponseClientPricingData } from 'app/main/pm/clients/models/client-pricing';
import { ClientService } from 'app/main/pm/services/clients/client.service';
import { BaseResultModel, TpValidationError } from 'app/models';
import { DropDownItem } from 'app/models/general/dropdownItem.model';
import { GlobalConfiguration } from 'app/models/general/global-configuration';
import { JobPriceData, PostQuoteJob, PostQuoteJobFile} from 'app/models/general/quote/post-models/post-quote-job';
import { ResponseQuoteJobData } from 'app/models/general/quote/response-models/response-quote-job-data';
import { RefDataService } from 'app/services/general/refData.service';
import { NotificationService } from 'app/services/notification.service';
import * as _ from 'lodash';
import {combineLatest, Subject} from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { DropDownOptionsModel } from '../../../../../models/general';
import { ValidateForm } from '../../../../../services/helpers/global-scope/form-validation-helper';
import { QuoteDateTypes } from '../../models/quote.model';
import {ResponseServiceData} from "../../../../admin/manage-services/models/response-models/response-service-data";
import {ChangedRateJobCombinationEvent, ChangedUnitJobCombinationEvent, JobCombinationsGridComponent} from "../../../shared/job-combinations-grid/job-combinations-grid.component";
import {QuoteStatusTypes} from "../../utils";
import {CurrencyserviceService} from "../../../../admin/services/manage-currency/currencyservice.service";
import {ResponseCurrencyConversionData} from "../../../../admin/models/response-models/response-currency-converstion-data";
import {filterClientPriceList, filterServicePriceList} from "../../../shared/functions";
import { HttpErrorResponse } from '@angular/common/http';
import {BaseOperationComponent} from "../../../../sharedComponents/base-operation/base-operation.component";
import { ResponseClientInfo } from 'app/main/pm/clients/models/client.model';
import {CalculateTotalsService} from "../../../../../services/calculate-totals/calculate-totals.service";
import {CalculateTotalValues} from "../../../../../models";
import {getService} from "../../../store/actions";
import {Store} from "@ngrx/store";
import {State} from "../../../../../store";
import {
    languageDropdownEntity,
    serviceDropdownEntity,
    servicesEntity,
    unitDropdownEntity
} from "../../../store/reducers";
import { QuoteService } from 'app/main/pm/services/quotes/quotes.service';
import { ResponseCatToolAnalysisResult } from 'app/main/admin/cat-count/models/cat-tool-analysis';
import { CatCountAnalysisModalComponent } from 'app/main/admin/cat-count/cat-count-analysis/cat-count-analysis-modal/cat-count-analysis-modal.component';
import { ResponseQuoteJobCatCount } from 'app/models/general/quote/response-models/response-quote-job-cat-count';
import { CatCountAnalyseModalComponent } from 'app/main/admin/cat-count/cat-count-analyse/cat-count-analyse-modal/cat-count-analyse-modal.component';
import { PostQuoteJobCatCount } from 'app/models/general/quote/post-models/post-quote-job-cat-count';
import { PutQuoteJobCatCount } from 'app/models/general/quote/put-models/put-quote-job-cat-count';
import {DateService} from "../../../../../services/date/date.service";
import {QuoteJobStatusTypes} from "../../../../../utills/quote-job-status-types";
import {ServiceDropDownItem} from "../../../../../store/models/dropdowns/service-dropdown-item";
import {TranslateService} from "@ngx-translate/core";
import {PostQuoteFile} from "../../models/post-quote-file";
import {FileTypes, removeNullProperties} from "../../../../../utills";
import {FileAutoUpload} from "../../../../../components/drag-and-drop-file/drag-and-drop-file.component";
import {ServiceTypes} from "../../../../../utills/enums/service-types";
import {WorkflowDropdownComponent} from "../../../../../components/workflow-dropdown/workflow-dropdown.component";

type EditQuoteJobForm = FormGroup & { value: PostQuoteJob }

export enum JobPriceDirection {
    custom = 'custom',
    clientPriceList = 'clientPriceList',
    servicePriceList = 'servicePriceList',
}

@Component({
    selector: 'quote-job',
    templateUrl: './quote-job.component.html',
    styleUrls: ['./quote-job.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class QuoteJobComponent extends BaseOperationComponent implements OnInit, OnDestroy {
    @ViewChild('workflowDropdown') workflowDropdown: WorkflowDropdownComponent;
    @ViewChild('jobCombinationGrid') jobCombinationGrid: JobCombinationsGridComponent;
    @ViewChild('catCountAnalysisModal',{static:false}) catCountAnalysisModal: CatCountAnalysisModalComponent;
    @ViewChild('catCountAnalyseModal',{static:false}) catCountAnalyseModal: CatCountAnalyseModalComponent;
    @Output() jobValidated = new EventEmitter<ResponseQuoteJobData[]>();
    @Input() quoteFolderForUpload: string;
    @Input() set client(client: ResponseClientInfo) {
        this._client = client;
        if (client) {
           if(client.clientRates) this._clientPriceList = client.clientRates;
           this.setClientDefaults();
        }
    }

    get client(): ResponseClientData {
        return this._client;
    }

    @Input() set clientId(clientId: number) {
        this._clientId = clientId;
        if (clientId) {
            this.getClientPriceList(clientId);
        }
    }

    get clientId(): number {
        return this._clientId;
    }

    @Input() set sectors(sectors: number[]) {
        if (sectors) {
            this._sectors = sectors;
        }
    }

    get sectors(): number[] {
        return this._sectors;
    }

    @Input() set quoteFiles(files: PostQuoteFile[]) {
        this._quoteFiles = files ?? [];
    }
    get quoteFiles(): PostQuoteFile[] {
        return this._quoteFiles;
    }

    private _client: ResponseClientData;
    private _clientId: number;
    units: DropDownItem[] = [];
    timeUnits: DropDownItem[] = [];
    dateTypes: DropDownOptionsModel[] = [];
    services: ServiceDropDownItem[] = [];
    displayServices: ServiceDropDownItem[] = [];
    languages: DropDownItem[] = [];
    rateTypes: DropDownItem[] = [];
    workflowSteps: DropDownItem[] = [];


    @Input() disabledDiscount: boolean = false;

    @Input() set quoteJob(job: ResponseQuoteJobData) {  
        this.initQuoteJob(job);
        this.autoUpload = {...this.autoUpload, entity: job.id ? {quoteJobId: job.id} : null};
    }

    get quoteJob(): ResponseQuoteJobData {
        return this._quoteJob;
    }

    private _quoteJob: ResponseQuoteJobData = new ResponseQuoteJobData();
    private _clientPriceList: ResponseClientPricingData[] = [];
    private _sectors: number[] = [];
    private _quoteFiles: PostQuoteFile[] = [];
    editQuoteJobForm: FormGroup;
    validationErrors: TpValidationError[] = [];
    //helper string labels
    targetLanguages: DropDownItem[] = [];
    quoteTargetLanguages: number[] = [];
    quoteSourceLanguage: number = null;
    selectedServiceId: number = null;
    public selectedServiceData: ResponseServiceData;
    private onDestroy$:Subject<void> = new Subject<void>();
    get rateTypeId (): number { return this.editQuoteJobForm.controls.rateTypeId.value }
    get rateCost (): number { return this.editQuoteJobForm.controls.rateCost.value }
    get rateControl(): AbstractControl { return this.editQuoteJobForm.controls.rateCost }
    get sourceLanguageControl(): AbstractControl { return this.editQuoteJobForm.controls.sourceLanguage }
    get targetLanguagesControl(): AbstractControl { return this.editQuoteJobForm.controls.targetLanguages }
    public quoteJobs: ResponseQuoteJobData[] = [];
    public files: PostQuoteJobFile[] = [];
    private currenciesWithConversionRates: ResponseCurrencyConversionData[] = [];
    public refreshingPriceListStatus: boolean = false;
    postCatCountObject:PostQuoteJobCatCount=new PostQuoteJobCatCount();  //will be used for new cj cat count discounts obj
    analysisResult:ResponseCatToolAnalysisResult=new ResponseCatToolAnalysisResult();
    catCountFigures?:ResponseQuoteJobCatCount=new ResponseQuoteJobCatCount(); //will hold temp cat count analysis
    autoUpload: Partial<FileAutoUpload<PostQuoteJobFile>> = {endpoint: '/quoteJob/file'};
    isMonolingual: boolean = false;
    isBilingual: boolean = false;
    constructor(
        private formBuilder: FormBuilder,
        private refDataService: RefDataService,
        private clientService: ClientService,
        private notificationService: NotificationService,
        private readonly changeDetector: ChangeDetectorRef,
        private globalConfiguration:GlobalConfiguration,
        private currencyService: CurrencyserviceService,
        private totalsService: CalculateTotalsService,
        private store: Store<State>,
        private quoteService:QuoteService,
        private dateService: DateService,
        private translateService: TranslateService
    ) {
        super();
        this.initDropdowns();
        this.initForm();
    }
    ngOnInit(): void {
        this.catCountFigures = new ResponseQuoteJobCatCount();
    }

    ngOnDestroy(): void {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
    initDropdowns(): void{
        this.store.select(serviceDropdownEntity.selectors.selectAll).subscribe(services => {
            this.services = services;
            this.displayServices = this.services.filter(service => service.isActive);
        });
        this.store.select(serviceDropdownEntity.selectors.selectByPredicate(entity => entity.isWorkflowStep)).subscribe(services => this.workflowSteps = services);
        this.store.select(unitDropdownEntity.selectors.selectAll).subscribe(units => this.units = units);
        this.store.select(languageDropdownEntity.selectors.selectAll).subscribe(languages => {
            this.languages = languages;
        });
        this.currencyService.getCurrenciesWithConversionRates().subscribe(res => {
            if (res.status && res.response) this.currenciesWithConversionRates = res.response;
        });
        this.dateTypes = [
            { textAsync: this.translateService.stream('tp-estimate-duration', {defaultText: 'Estimate Duration'}), value: QuoteDateTypes.estimated },
            { textAsync: this.translateService.stream('tp-specific-dates', {defaultText: 'Specific Dates'}), value: QuoteDateTypes.concrete },
        ];
        this.refDataService.getRateTypes().pipe(takeUntil(this.onDestroy$)).subscribe(result=> {
            if(result.status) this.rateTypes = result.response;
        });
        this.refDataService.getUnitsByCategory('TIME').pipe(takeUntil(this.onDestroy$)).subscribe(result=>{
            if(result.status) this.timeUnits = result.response;
        });
    }
    initForm(): void{
        this.editQuoteJobForm = this.formBuilder.group({
            name: new FormControl(null, [Validators.required]),
            service: new FormControl(null, [Validators.required]),
            workflowSteps: new FormControl(null),
            sourceLanguage: new FormControl(null, [Validators.required]),
            targetLanguage: new FormControl(null),
            targetLanguages: new FormControl(null, [Validators.required]),
            volume: new FormControl(null, [Validators.required]),
            volumeUnit: new FormControl(null, [Validators.required]),
            rateTypeId: new FormControl(1, [Validators.required]),
            rateCost: new FormControl(null),
            tax: new FormControl(null),
            discountOrSurcharge: new FormControl(null),
            discountOrSurchargeReason: new FormControl(null),
            jobComments: new FormControl(null),
            fileIds: new FormControl(null),
            durationType: new FormControl(QuoteDateTypes.estimated),
            estimatedDuration: new FormControl(null, [Validators.required]),
            estimatedDurationUnit: new FormControl(null, [Validators.required]),
            startDate: new FormControl(null),
            endDate: new FormControl(null),
        }) as EditQuoteJobForm;
        this.editQuoteJobForm.controls.workflowSteps.disable();
        this.handleFormChanges();
    }
    initQuoteJob(job: ResponseQuoteJobData){
        this._quoteJob = _.cloneDeep(job);
        if (job) {
            this.loadFormData(job);
            this.displayServices = this.services.filter(service => service.isActive || service.id == job.serviceId);
            this.selectedServiceId = job.serviceId;
            if (this.selectedServiceId){
                this.editQuoteJobForm.controls.workflowSteps.enable();
            }
            this.getServiceData(job.serviceId, false);
            this.quoteTargetLanguages = job.targetLanguageId ? [job.targetLanguageId] : [];
            job.priceData = new JobPriceData();
            if(job.quoteJobFilesResponse) this.files = job.quoteJobFilesResponse;
            //check for stored cat count analysis
            if(job?.id>0){
                this.getQuoteJobCatCount(job.id);
            }
            this.changeDetector.markForCheck();
        } else {
            this.editQuoteJobForm.reset({rateTypeId: 1});
            this.changeDetector.markForCheck();
        }
    }
    loadFormData(job: ResponseQuoteJobData): void {
        const formValue = {
            name: job.description || null,
            tax: job.vat || null,
            sourceLanguage: job.sourceLanguageId || null,
            targetLanguage: job.targetLanguageId || null,
            targetLanguages: job.targetLanguageId ? [job.targetLanguageId] : null,
            durationType: (job.isTimelineEstimated) ? QuoteDateTypes.estimated : QuoteDateTypes.concrete ,
            isTimelineEstimated: job.isTimelineEstimated || null,
            estimatedDurationUnit: job.timeLineUnitId || null,
            timeLineUnitValue: job.timeLineUnitValue || null,
            startDate: job.startDate ? this.dateService.toTimeZone(job.startDate) : null,
            endDate: job.endDate ? this.dateService.toTimeZone(job.endDate) : null,
            discountOrSurcharge: -job.discount || job.extraCharge || null,
            discountOrSurchargeReason: job.discountExtraChargeReason || null,
            rateTypeId: (job.rateTypeId) ? Number(job.rateTypeId) : 1,
            rateCost: (job.rate) ? Number(job.rate): null,
            volume: job.volume || null,
            volumeUnit: job.unitId || null,
            estimatedDuration: job.timeLineUnitValue || null,
            jobComments: job.comments || null,
        };
        this.editQuoteJobForm.patchValue(formValue);
        this.editQuoteJobForm.patchValue({service: job.serviceId || null},{emitEvent: false});
        if(this.workflowDropdown) {
            this.workflowDropdown.value = {
                workflows: job.workflowStepLists ?? [],
                workflowSteps: job.workflowSteps ?? []
            };
        }
    }
    handleFormChanges(): void{
        this.editQuoteJobForm.controls.startDate.disable();
        this.editQuoteJobForm.controls.endDate.disable();

        this.editQuoteJobForm.controls.service.valueChanges.subscribe(serviceId => {
            this.selectedServiceId = serviceId;
            this.editQuoteJobForm.controls.workflowSteps.enable();
        });

        this.editQuoteJobForm.controls.durationType.valueChanges.subscribe((durationType: QuoteDateTypes) => {
            this.editQuoteJobForm.controls.startDate.disable();
            this.editQuoteJobForm.controls.endDate.disable();
            this.editQuoteJobForm.controls.estimatedDuration.disable();
            this.editQuoteJobForm.controls.estimatedDurationUnit.disable();
            if (durationType === QuoteDateTypes.estimated) {
                this.editQuoteJobForm.controls.estimatedDuration.enable();
                this.editQuoteJobForm.controls.estimatedDurationUnit.enable();
            } else if (durationType === QuoteDateTypes.concrete) {
                this.editQuoteJobForm.controls.startDate.enable();
                this.editQuoteJobForm.controls.endDate.enable();
            }
            this.changeDetector.markForCheck();
        });
        this.editQuoteJobForm.controls.service.valueChanges.subscribe(serviceId => {
            this.getServiceData(serviceId);
        });

        this.editQuoteJobForm.controls.sourceLanguage.valueChanges.subscribe(sourceLanguage => {
            this.setSourceLanguage(sourceLanguage);
            const targetLanguages = this.editQuoteJobForm.get('targetLanguages').value;
            if (this.isBilingual && targetLanguages?.length > 0 && sourceLanguage) {
                this.buildQuoteJobs();
            } else {
                this.quoteJobs = [];
            }
        });

        this.editQuoteJobForm.controls.targetLanguages.valueChanges.subscribe(targetLanguages => {
            const sourceLanguage = Number(this.editQuoteJobForm.get('sourceLanguage').value);
            const excludeTargetLanguages = [].concat(this.quoteTargetLanguages);
            this.setTargetLanguages(targetLanguages);
            if (this.isBilingual && targetLanguages?.length > 0 && sourceLanguage) {
                this.buildQuoteJobs(excludeTargetLanguages);
            } else {
                this.quoteJobs = [];
            }
        });

        this.editQuoteJobForm.controls.volume.valueChanges.subscribe(volume => {
            if (this.quoteJobs?.length > 0) {
                this.quoteJobs.map(job => {
                    job.volume = volume
                    job.totalAmount = this.totalsService.totalAmount(job);
                    return job;
                });
            }
        });

        this.editQuoteJobForm.controls.rateCost.valueChanges.subscribe(rate => {
            if (this.quoteJobs?.length > 0) {
                this.quoteJobs.map(job => {
                    job.rate = rate;
                    job.totalAmount = this.totalsService.totalAmount(job);
                    this.checkConvertRate(job, JobPriceDirection.custom);
                    this.checkPriceList(job, rate ?? 0);
                    return job;
                });
                if(this.jobCombinationGrid?._quoteJobs) this.jobCombinationGrid._quoteJobs = this.jobCombinationGrid._quoteJobs.map((job: any) => {
                    job.hasCustomValue = !!rate;
                    return job;
                });
            } else {
                this.checkConvertRate(this.quoteJob, JobPriceDirection.custom);
                this.checkPriceList({sourceLanguageId: null, targetLanguageId: null, unitId: this.editQuoteJobForm.controls.volumeUnit.value }, rate ?? 0);
            }
        });

        this.editQuoteJobForm.controls.tax.valueChanges.subscribe(tax => {
            if (this.quoteJobs?.length > 0) {
                this.quoteJobs.map(job => {
                    job.vat = tax;
                    job.totalAmount = this.totalsService.totalAmount(job);
                    return job;
                });
            }
        });

        this.editQuoteJobForm.controls.discountOrSurcharge.valueChanges.subscribe(discountOrSurcharge => {
            if (this.quoteJobs?.length > 0) {
                const discountOrSurchargeValues = this.totalsService.discountOrExtraChargeValues(discountOrSurcharge);
                this.quoteJobs.map(job => {
                    job.discountOrSurcharge = discountOrSurcharge;
                    job.discount = discountOrSurchargeValues.discount;
                    job.extraCharge = discountOrSurchargeValues.extraCharge;
                    job.totalAmount = this.totalsService.totalAmount(job);
                    return job;
                });
            }
        });

        this.editQuoteJobForm.valueChanges.subscribe(values => {
            const calculateTotalValues: CalculateTotalValues = {
                ...this.totalsService.discountOrExtraChargeValues(values.discountOrSurcharge),
                rate: values.rateCost, rateTypeId: values.rateTypeId, volume: values.volume, vat: values.tax
            };
            this._quoteJob.totalAmount = this.totalsService.totalAmount(calculateTotalValues);
        });
    }

    isSelectedCurrencyMatchingWithClientCurrency(): boolean{
        return (this.quoteJob?.currencyId === this._client?.currencyId);
    }

    getClientPriceList(clientId: number): void {
        this.clientService.getClientPriceListByClientId(clientId).subscribe(result => {
            if(result.response) {
                this._clientPriceList = result.response;
                this.editQuoteJobForm.patchValue({rateCost: this.editQuoteJobForm.controls.rateCost.value});
            }
        });
    }
    /**
     * @function getServiceData
     * @param id number
     * @param defaults boolean
     * @description get service data and auto populate service defaults into form
     * @return void
     * */
    getServiceData(id: number, defaults: boolean = true): void {
        if(id) {
            this.store.dispatch(getService({id}));
            this.store.select(servicesEntity.selectors.selectById(id)).pipe(takeUntil(this.onDestroy$)).subscribe(serviceData => {
                this.selectedServiceData = serviceData;
                if(serviceData) {
                    this.isMonolingual = this.selectedServiceData.serviceTypeId == ServiceTypes.Monolingual;
                    this.isBilingual = this.selectedServiceData.serviceTypeId == ServiceTypes.Bilingual;
                    if (this.selectedServiceData?.unitId && defaults) {
                        this.editQuoteJobForm.controls.volumeUnit.reset(this.selectedServiceData.unitId);
                        this.unitChanged(this.selectedServiceData.unitId);
                    }
                    if(this.isMonolingual){
                        this.targetLanguagesControl.setValue(null);
                        this.targetLanguagesControl.disable();
                        this.sourceLanguageControl.enable();
                        this.rateControl.setValidators([Validators.required]);
                        this.rateControl.updateValueAndValidity();
                    } else if(this.isBilingual){
                        this.sourceLanguageControl.enable();
                        this.targetLanguagesControl.enable();
                        this.rateControl.clearValidators();
                        this.rateControl.updateValueAndValidity();
                    } else {
                        this.sourceLanguageControl.setValue(null);
                        this.targetLanguagesControl.setValue(null);
                        this.sourceLanguageControl.disable();
                        this.targetLanguagesControl.disable();
                        this.rateControl.setValidators([Validators.required]);
                        this.rateControl.updateValueAndValidity();
                    }
                    this.changeDetector.detectChanges();
                }
            });
        }
    }

    checkConvertRate(job: ResponseQuoteJobData, jobPriceDirection: JobPriceDirection = JobPriceDirection.custom): ResponseQuoteJobData{
        if(job?.priceData) {
            job.priceData.direction = jobPriceDirection;
            job.priceData.rate = job.rate;
            const companyBranchSetting = this.globalConfiguration.companyBranchSetting;
            switch (jobPriceDirection) {
                case JobPriceDirection.custom:
                    job.priceData.isConverted = false;
                    job.priceData.rate = job.rate;
                    job.priceData.isCurrencyMatching = true;
                    job.priceData.isCurrencyMatchingWithClientCurrency = this.isSelectedCurrencyMatchingWithClientCurrency();
                    break;
                case JobPriceDirection.clientPriceList:
                    job.priceData.isCurrencyMatchingWithClientCurrency = this.isSelectedCurrencyMatchingWithClientCurrency();
                    if (this.quoteJob?.currencyId === this._client.currencyId) {
                        job.priceData.isConverted = false;
                        job.priceData.isCurrencyMatching = true;
                        job.priceData.rate = job.rate;
                    } else {
                        job.priceData.isCurrencyMatching = false;
                        //this.convertRate(job, this.client.currencyCode, this.quoteJob?.currencyCode);
                    }
                    break;
                case JobPriceDirection.servicePriceList:
                    job.priceData.isCurrencyMatchingWithClientCurrency = this.isSelectedCurrencyMatchingWithClientCurrency();
                    if (this.quoteJob?.currencyId === companyBranchSetting.companyBranchFinanceData.currencyId) {
                        job.priceData.isConverted = false;
                        job.priceData.isCurrencyMatching = true;
                    } else {
                        job.priceData.isCurrencyMatching = false;
                        //this.convertRate(job, companyBranchSetting.currencyCode, this.quoteJob?.currencyCode);
                    }
                    break;
            }
        }
        return job;
    }

    private convertRate(job: ResponseQuoteJobData, base: string, target: string): ResponseQuoteJobData{
        const baseCurrencyConversionRates = this.findBaseCurrencyConversionRates(base ?? '');
        if ((baseCurrencyConversionRates?.rates?.length ?? 0)>0) {
            if (baseCurrencyConversionRates?.rates[target]) {
                job.priceData.isConverted = true;
                const targetRate = Number(parseFloat((job.rate * baseCurrencyConversionRates?.rates[target]).toString()).toFixed(2));
                const baseRate = job.rate;
                job.priceData.conversion = {base, target, baseRate, targetRate};
                job.rate = targetRate;
                job.priceData.rate = targetRate;
                this.changeDetector.markForCheck();
            } else { job.priceData.isConverted = false; }
        } else {
            job.priceData.isConverted = false;
            job.priceData.rate = job.rate;
        }
        return job;
    }

    findBaseCurrencyConversionRates (base: string): ResponseCurrencyConversionData {
        if((this.currenciesWithConversionRates?.length ?? 0)>0){
            return this.currenciesWithConversionRates?.find(currency => currency?.isoCode === base);
        }
        else{
            return null;
        }
    }

    checkPriceList(job: ResponseQuoteJobData, rate: number = null): ResponseQuoteJobData{
        if (this._clientPriceList.length > 0 && this.selectedServiceData) { // filter client price list
            const filteredClientPriceList = filterClientPriceList(this.selectedServiceData, this._clientPriceList,
                {sourceLanguage: job.sourceLanguageId, targetLanguages:[job.targetLanguageId], unit: job.unitId, sectors: this.sectors, rate }
            );
            if (filteredClientPriceList.length > 0) {  // apply default client pricing
                if(this.isBilingual) {
                    job.rate = filteredClientPriceList[0].rate;
                    job.unitId = filteredClientPriceList[0].unitId;
                    job.rateTypeId = filteredClientPriceList[0].rateTypeId;
                    this.checkConvertRate(job, JobPriceDirection.clientPriceList);
                } else {
                    this.editQuoteJobForm.patchValue({rateCost: filteredClientPriceList[0].rate}, {emitEvent: false});
                    this.checkConvertRate(this.quoteJob, JobPriceDirection.clientPriceList);
                }
                return job;
            }
        }
        if(this.selectedServiceData?.priceList?.length > 0){ // filter  service price list
            const filteredServicePriceList = filterServicePriceList(this.selectedServiceData,
                {sourceLanguage: job.sourceLanguageId, targetLanguages:[job.targetLanguageId], unit: job.unitId, sectors: this.sectors, rate }
            );
            if (filteredServicePriceList.length > 0) {
                if(this.isBilingual) {
                    job.rate = filteredServicePriceList[0].rate;
                    job.unitId = filteredServicePriceList[0].units[0].id;
                    job.rateTypeId = 1;
                    this.checkConvertRate(job, JobPriceDirection.servicePriceList);
                } else {
                    this.editQuoteJobForm.patchValue({rateCost: filteredServicePriceList[0].rate}, {emitEvent: false});
                    this.checkConvertRate(this.quoteJob, JobPriceDirection.servicePriceList);
                }
                return job;
            }
        }
        return job;
    }

    onChangedUnitCombination(event: ChangedUnitJobCombinationEvent): void{
        event.job.unitId = event.unitId;
        this.checkConvertRate(event.job, JobPriceDirection.custom);
        this.checkPriceList(event.job);
    }

    onChangedRateCombination(event: ChangedRateJobCombinationEvent): void {
        event.job.rate = event.rate;
        this.checkConvertRate(event.job, JobPriceDirection.custom);
        this.checkPriceList(event.job, event.rate ?? 0);
    }
    
    onRefreshCombinationsPriceList(): void {        
        if (!this.refreshingPriceListStatus) {
            this.notificationService.startSpinner();
            this.refreshingPriceListStatus = true;
            combineLatest(this.clientService.getClientPriceListByClientId(this.client.id), this.store.select(servicesEntity.selectors.selectById(this.selectedServiceData.id))).subscribe(
                ([clientPriceList, serviceData]) => {
                    this.notificationService.stopSpinner();
                    this.refreshingPriceListStatus = false;
                    if (clientPriceList.response) {
                        this._clientPriceList = clientPriceList.response;
                    }
                    if (serviceData) {
                        this.selectedServiceData = serviceData;
                    }                    
                    if(this.quoteJobs.length > 0) {
                        this.quoteJobs.forEach(quoteJob => {
                            this.checkPriceList(quoteJob);
                        });
                    } else {
                        this.checkPriceList({sourceLanguageId: null, targetLanguageId: null, unitId: this.editQuoteJobForm.controls.volumeUnit.value});
                    }
                    this.notificationService.showSuccess('','Price List Refreshed Successfully');
                    this.changeDetector.markForCheck();
                },
                error => {
                    this.notificationService.stopSpinner();
                    this.refreshingPriceListStatus = false;
                }
            );
        }
    }

    //enable or disable controls depending ratetype
    enableControlsBasedOnCostType(newCostType: number) {
        this.editQuoteJobForm.controls.rateCost.enable();
        // this.editQuoteJobForm.controls.rateCost.setValue(null);
        if (newCostType === 3) {  //Free
            this.editQuoteJobForm.controls.rateCost.disable();
            this.editQuoteJobForm.controls.rateCost.setValue(null);
        }

        if (this.quoteJobs?.length > 0) {
            this.quoteJobs.map(job => {
                job.rateTypeId = newCostType;
                job.totalAmount = this.totalsService.totalAmount(job);
                return job;
            });
        }
        this.changeDetector.markForCheck();
    }    

    //sets form's default values by selected client
    setClientDefaults(){
        //set rate type
        this.editQuoteJobForm.controls.rateTypeId.setValue(1);  //rate/unit
        //currency - vat 
        if(this.client){
            //client's vat
            if(this.client.defaultTaxRate){
                this.editQuoteJobForm.controls.tax.setValue(this.client.defaultTaxRate);
            } 
        }
    }

    buildQuoteJobs(excludeTargetLanguages: number[] = []): void {
        const resQuoteJob = this.getQuoteJobData();
        const quoteJobs = this.getQuoteJobsData(resQuoteJob, excludeTargetLanguages);
        if(excludeTargetLanguages.length == 0) this.quoteJobs = [];
        this.quoteJobs = this.quoteJobs.filter( qj => { if(this.quoteTargetLanguages.includes(qj.targetLanguageId)) return qj; } );
        quoteJobs.forEach(qj => { this.quoteJobs.push(qj) });
        this.quoteJobs = [...this.quoteJobs];
        this.changeDetector.markForCheck();
    }

    saveJob(): void {
        if (!ValidateForm(this.editQuoteJobForm)) {return;}
        this.jobValidated.emit(this.prepareQuoteJobsPayload());
    }

    private prepareQuoteJobsPayload(): ResponseQuoteJobData[]{
        let response: ResponseQuoteJobData[] = [];
        const quoteJobData = this.getQuoteJobData();
        let quoteJobFilesMetadata: PostQuoteJobFile[] = [];
        this.files.forEach((file, fileIndex) => {
            file = removeNullProperties(file);
            quoteJobFilesMetadata.push({...file, file: undefined});
        });
        quoteJobData.quoteJobFiles = quoteJobFilesMetadata;
        quoteJobData.quoteJobFilesResponse = quoteJobFilesMetadata;
        if(this.isBilingual) {
            if(this.quoteJobs?.length > 0) {
                this.quoteJobs.map(job => {
                    job.id = this.isUpdatedJob(job) ? this.quoteJob?.id : 0;
                    job.quoteJobStatusId = this.isUpdatedJob(job) ? this.quoteJob?.quoteJobStatusId : QuoteStatusTypes.OPEN;
                    job.description = quoteJobData.description;
                    job.serviceId = quoteJobData.serviceId;
                    job.comments = quoteJobData.comments;
                    job.workflowSteps = quoteJobData.workflowSteps;
                    job.workflowStepLists = quoteJobData.workflowStepLists;
                    job.discountExtraChargeReason = quoteJobData.discountExtraChargeReason;
                    job.startDate = quoteJobData.startDate;
                    job.endDate = quoteJobData.endDate;
                    job.isTimelineEstimated = quoteJobData.isTimelineEstimated;
                    job.timeLineUnitId = quoteJobData.timeLineUnitId;
                    job.timeLineUnitValue = quoteJobData.timeLineUnitValue;
                    this.addJobDescriptions(job);
                    if(this.isAdd()) job.catCountDiscounts = quoteJobData.catCountDiscounts;
                    job.quoteJobFiles = quoteJobFilesMetadata;
                    if(!this.isUpdatedJob(job)) job.quoteJobFiles = job.quoteJobFiles.map(file => { return {...file, id: undefined}});
                    return job;
                });
                // if(this.isEdit() && this.quoteJob?.id && !this.quoteJobs.find(job => job.id != 0)) this.quoteJobs[0].id = this.quoteJob?.id;
                response = this.quoteJobs;
            }
        } else {
            response = this.getQuoteJobsData(quoteJobData);
        }
        return response;
    }
    /**
     *@function addSelectedFiles
     * @description add selected files from quote
     * @return PostQuoteJobFile[]
     * */
    addSelectedFiles(files: PostQuoteFile[], fileType: FileTypes): void {
        files.map(file => {
            file.id = undefined;
            if(!file?.uid) file.uid = Math.random().toString(16).slice(2);
            file.fileTypeId = fileType;
        });
        this.files = [...this.files, ...files];
    }

    isUpdatedJob(job: ResponseQuoteJobData): boolean{
        return (this.quoteJob && this.quoteJob?.id == job.id && job.sourceLanguageId == this.quoteJob.sourceLanguageId && job.targetLanguageId == this.quoteJob.targetLanguageId);
    }

    get rateAddable(): boolean {
        return true;
    }

    isRatePositive(): boolean{
        return !(Number(this.rateCost) > 0) || this.quoteJob?.priceData?.direction === JobPriceDirection.clientPriceList || !this.quoteJob?.priceData?.isCurrencyMatchingWithClientCurrency || this.isBilingual;
    }

    /**
     * @description call API to get (only once) client's pricelist
     */

    setTargetLanguages(e: number[]): void{
        this.quoteTargetLanguages = [].concat(e);
    }
    
    setSourceLanguage(e: number): void{
        this.quoteSourceLanguage = e;
    }

    getQuoteJobData(): ResponseQuoteJobData {
        const formValue = _.cloneDeep(this.editQuoteJobForm.value);
        const workflowValue = this.workflowDropdown?.getWorkflowValue() ?? {workflows: [], workflowSteps: []};
        let resQuoteJob: ResponseQuoteJobData = <ResponseQuoteJobData>{
            ...this._quoteJob,
            rateTypeId: Number(formValue.rateTypeId),
            targetLanguageId: null,
            rate: Number(formValue.rateCost),
            workflowSteps: workflowValue.workflowSteps,
            workflowStepLists: workflowValue.workflows,
            discountExtraChargeReason: formValue.discountOrSurchargeReason,
            description: formValue.name,
            serviceId: formValue.service,
            sourceLanguageId: this.quoteSourceLanguage,
            discountOrSurcharge: formValue.discountOrSurcharge,
            discount: (formValue?.discountOrSurcharge < 0) ? -formValue.discountOrSurcharge : null,
            volume: Number( (formValue.volume) ? formValue.volume : null),
            startDate: formValue.durationType === QuoteDateTypes.concrete ? this.dateService.toUTC(formValue.startDate) : null,
            endDate: formValue.durationType === QuoteDateTypes.concrete ? this.dateService.toUTC(formValue.endDate) : null,
            insertUserId: this.globalConfiguration.currentUser.id,
            comments: formValue.jobComments,
            unitId: formValue.volumeUnit,
            vat: Number(formValue.tax),
            extraCharge: (formValue?.discountOrSurcharge > 0) ? formValue.discountOrSurcharge : null,
            timeLineUnitValue: formValue.durationType === QuoteDateTypes.estimated ? Number(formValue.estimatedDuration) : null,
            timeLineUnitId: formValue.durationType === QuoteDateTypes.estimated ? formValue.estimatedDurationUnit:null,
            isTimelineEstimated: formValue.durationType === QuoteDateTypes.estimated,
            priceData: new JobPriceData(),
        };

        if(this.isAdd()){
            resQuoteJob.catCountDiscounts = this.postCatCountObject;
        }
        resQuoteJob.totalAmount = this.totalsService.totalAmount(resQuoteJob).toString();
        return resQuoteJob;
    }

    getQuoteJobsData(qj:ResponseQuoteJobData, excludeTargetLanguages: number[] = []): ResponseQuoteJobData[]{
        let emittedQuoteJobs:ResponseQuoteJobData[] = [];
        if (this.quoteTargetLanguages?.length > 0) {
            for(let i = 0; i < this.quoteTargetLanguages?.length; i++) {
                if(!excludeTargetLanguages.includes(this.quoteTargetLanguages[i])) {
                    emittedQuoteJobs[i] = _.cloneDeep(qj);
                    emittedQuoteJobs[i].targetLanguageId = this.quoteTargetLanguages[i];
                    // check price list
                    if (this.isBilingual) {
                        this.checkConvertRate(emittedQuoteJobs[i], JobPriceDirection.custom);
                        this.checkPriceList(emittedQuoteJobs[i]);
                    }
                    this.addJobDescriptions(emittedQuoteJobs[i]);
                }
            }
        } else {
            qj.targetLanguageId = null;
            this.addJobDescriptions(qj);
            emittedQuoteJobs = [qj];
        }
        return emittedQuoteJobs;
    }
    /***
     @function addJobDescriptions
     @description add job description properties
     @param qj ResponseQuoteJobData
     @return void
     */
    addJobDescriptions(qj: ResponseQuoteJobData): ResponseQuoteJobData {
        qj.quoteJobStatusId = QuoteJobStatusTypes.Open;
        let sourceLanguage = this.languages.find(x=> x.id === qj.sourceLanguageId)?.name;
        let targetLanguage = this.languages.find(x=> x.id === qj.targetLanguageId)?.name;
        qj.serviceDescription = this.services.find(x=> x.id === qj.serviceId)?.name;
        if(sourceLanguage && targetLanguage) qj.serviceDescription += ' ' + sourceLanguage + ' -> ' + targetLanguage;
        qj.unitDescription = this.units.find(x=> x.id == qj.unitId)?.name;
        if(qj.isTimelineEstimated) {
            qj.timeLineUnitDescription = this.timeUnits.find(x=> x.id === qj.timeLineUnitId)?.name;
        }
        return qj;
    }


    onClickAddPriceToCombination(obj) {
        const formValue = this.editQuoteJobForm.value;
        const data = {
            rate: Number(obj?.rate),
            clientId: this._clientId,
            serviceId: formValue.service,
            currencyId: this.quoteJob?.currencyId,
            rateTypeId: formValue.rateTypeId,
            sourceLanguageId: formValue.sourceLanguage,
            targetLanguages: [obj?.targetLanguageId],
            unitId: obj.unitId,
            comments: formValue.jobComments,
            isActive: true
        };
        if( !data.rate) {return;}
        this.clientService.addClientPriceList(data).pipe(takeUntil(this.onDestroy$)).subscribe(res => {
                if (res.status) {
                    this.notificationService.showSuccess('Price added to Client Price List Successfully.','Info');
                    this.onRefreshCombinationsPriceList();
                } else {
                    this.notificationService.showError(res.message,'Warning');
                }   
            }, (error:HttpErrorResponse) => {
                this.notificationService.showError('A network / server error occured','Warning');
            }
        );
    }

    onClickAddPriceToAllCombinations() {
        if (this.rateAddable) {
            const formValue = this.editQuoteJobForm.value;
            const data = {
                rate: Number(formValue?.rateCost),
                clientId: this._clientId,
                serviceId: formValue.service,
                currencyId: this.quoteJob?.currencyId,
                rateTypeId: formValue.rateTypeId,
                sourceLanguageId: formValue.sourceLanguage,
                targetLanguages: formValue?.targetLanguages,
                unitId: formValue.volumeUnit,
                comments: formValue.jobComments,
                isActive: true
            };

            this.notificationService.startSpinner();
            this.clientService.updateClientPriceList(data).pipe(
                takeUntil(this.onDestroy$),
                finalize(() => {
                    this.notificationService.stopSpinner();
                })
            ).subscribe(res => {
                this.notificationService.showSuccess('','Price added to Client Price List Successfully.');
                this.onRefreshCombinationsPriceList();
                if(this.isBilingual) this.buildQuoteJobs();
            });
        }
    }

    enterManually(): void {
        this.catCountAnalyseModal.hide();
        this.catCountAnalysisModal.clientId = this.clientId;
        this.catCountAnalysisModal.show();
    }

    /**
     * @description uploads a csv file for analysis
     * @param e 
     */
    uploadCsvFile(e:any){
        this.notificationService.startSpinner();

        //update client first
        e.clientId=this.clientId;

        //check if we had catCountFigures already from db
        if(!this.catCountFigures?.id){
            //update cat tool Id used in relative object
            this.catCountFigures=new ResponseQuoteJobCatCount();
        }
        
        //store the selected cat tool Id used for the analysis
        this.catCountFigures.catToolId=e.catToolId;

        var formdata:FormData=new FormData();

        for (const prop in e) {
            if (!e.hasOwnProperty(prop)) { continue; }
            formdata.append(prop , e[prop]);        
        }       

        this.refDataService.uploadCatToolCsv(formdata)
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(
            (res:BaseResultModel<ResponseCatToolAnalysisResult>)=>{
                if(res.status){
                    this.catCountAnalysisModal.hide();
                    this.analysisResult=res.response;

                    //since we are talking about a new analysis we need resource Id to get discounts
                    this.catCountAnalysisModal.clientId=this.clientId;

                    this.catCountAnalysisModal.analysisResult=this.analysisResult;

                    //every time user uploads new cat count csv cat count figures should not be taken into account
                    this.catCountAnalysisModal.show();
                }
                else{
                    this.notificationService.showError(res.message,'Warning');
                }

                this.notificationService.stopSpinner();
            },
            (error:HttpErrorResponse)=>{
                console.log(error);
                this.notificationService.stopSpinner();
                this.notificationService.showError('An error occured while trying to analyze the file.','Warning');
            }
        );
    }

    getDiscountedWords(e:any){
        if(e?.totalDiscounted>0 && e?.totalWordCount>0){
            this.editQuoteJobForm.controls.volume.setValue(e.totalDiscounted);  

            //save cat count analysis if we have an already registered resource job
            if(this.isEdit()){
                this.postCatCountObject=null;
                this.saveQuoteJobCatCount(e); 
            }   
            else if(this.isAdd()){
                this.postCatCountObject=this.buildPostCatCountModel(e);
            }         
        }
        else if(e?.totalDiscounted==0 && e?.totalWordCount==0){
            this.catCountAnalyseModal.show();
            this.changeDetector.markForCheck();
        }
    }

    getQuoteJobCatCount(quoteJobId:number){
        if(quoteJobId>0){
            this.quoteService.getQuoteJobCatCount(quoteJobId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (res:BaseResultModel<ResponseQuoteJobCatCount>)=>{
                    if(res.status){
                        this.catCountFigures=res.response;

                        if(!this.catCountFigures){
                            this.catCountFigures=new ResponseQuoteJobCatCount();
                            this.catCountFigures.isFresh=true;
                        }
                        else{
                            this.catCountFigures.isFresh=false;
                            this.catCountAnalysisModal.catCountFigures=this.catCountFigures;
                        }   

                        this.changeDetector.markForCheck();
                    }
                },
                (error:HttpErrorResponse)=>{
                    console.log(error);
                }
            );
        }
    }

    saveQuoteJobCatCount(e:any){
        if(e){
            //determine whether we should add new or update cat count figures
            if(this.catCountFigures?.id){
                this.updateQuoteJobCatCount(e);
            }
            else{
                this.addQuoteJobCatCount(e);
            }
        }
    }

    addQuoteJobCatCount(e:any){
        if(e){

            //form cat count object
            var pObj:PostQuoteJobCatCount=<PostQuoteJobCatCount>{
                insertUserId:this.globalConfiguration.currentUser.id,
                catCountFor:(e.discounts.catCountFor)?e.discounts.catCountFor:'',
                xtranslatedPerc:e.discounts.xtranslatedPerc,
                repetitionsPerc:e.discounts.repetitionsPerc,
                match100Perc:e.discounts.match100Perc,
                match9599Perc:e.discounts.match9599Perc,
                match8594Perc:e.discounts.match8594Perc,
                match7584Perc:e.discounts.match7584Perc,
                match5074Perc:e.discounts.match5074Perc,
                noMatchPerc:e.discounts.noMatchPerc,
                crossFileRepetitionsPerc:e.discounts.crossFileRepetitionsPerc,
                match101Perc:e.discounts.match101Perc,
                contextExact:e.discounts.contextMatch,
                quoteJobId:this._quoteJob?.id,
                catToolId:this.catCountFigures.catToolId,
                totalWordsCount:e.totalWordCount,
                totalDiscountedWords:e.totalDiscounted,
                xtranslatedDiscount:e.discounts.xtranslatedDiscount,
                repetitionsDiscount:e.discounts.repetitionsDiscount,
                match100Discount:e.discounts.match100Discount,
                match101Discount:e.discounts.match101Discount,
                match9599Discount:e.discounts.match9599Discount,
                match5074Discount:e.discounts.match5074Discount,
                match7584Discount:e.discounts.match7584Discount,
                noMatchDiscount:e.discounts.noMatchDiscount,
                crossFileRepetitionsDiscount:e.discounts.crossFileRepetitionsDiscount,
                contextExactDiscount:e.discounts.contextMatchDiscount,
                match8594Discount:e.discounts.match8594Discount
            };

            this.quoteService.addQuoteJobCatCount(pObj)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (res:BaseResultModel<ResponseQuoteJobCatCount>)=>{
                    if(res.status){
                        this.catCountFigures=res.response;
                        this.catCountFigures.isFresh=false;

                        //clear other parameters in order to use only catCountFigures
                        this.clearUnusedCatCountModalParameters();
                        this.changeDetector.markForCheck();
                    }
                },
                (error:HttpErrorResponse)=>{
                    console.log(error);
                }
            );
        }
    }

    updateQuoteJobCatCount(e:any){
        if(e){
            //form cat count object
            var pObj:PutQuoteJobCatCount=<PutQuoteJobCatCount>{
                id:this.catCountFigures.id,
                insertUserId:this.globalConfiguration.currentUser.id,
                catCountFor:e.discounts.catCountFor,
                xtranslatedPerc:e.discounts.xtranslatedPerc,
                repetitionsPerc:e.discounts.repetitionsPerc,
                match100Perc:e.discounts.match100Perc,
                match9599Perc:e.discounts.match9599Perc,
                match8594Perc:e.discounts.match8594Perc,
                match7584Perc:e.discounts.match7584Perc,
                match5074Perc:e.discounts.match5074Perc,
                noMatchPerc:e.discounts.noMatchPerc,
                crossFileRepetitionsPerc:e.discounts.crossFileRepetitionsPerc,
                match101Perc:e.discounts.match101Perc,
                contextExact:e.discounts.contextMatch,
                quoteJobId:this._quoteJob?.id,
                catToolId:this.catCountFigures.catToolId,
                totalWordsCount:e.totalWordCount,
                totalDiscountedWords:e.totalDiscounted,
                xtranslatedDiscount:e.discounts.xtranslatedDiscount,
                repetitionsDiscount:e.discounts.repetitionsDiscount,
                match100Discount:e.discounts.match100Discount,
                match101Discount:e.discounts.match101Discount,
                match9599Discount:e.discounts.match9599Discount,
                match5074Discount:e.discounts.match5074Discount,
                match7584Discount:e.discounts.match7584Discount,
                noMatchDiscount:e.discounts.noMatchDiscount,
                crossFileRepetitionsDiscount:e.discounts.crossFileRepetitionsDiscount,
                contextExactDiscount:e.discounts.contextMatchDiscount,
                match8594Discount:e.discounts.match8594Discount
            };

            this.quoteService.updateQuoteJobCatCount(pObj)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (res:BaseResultModel<ResponseQuoteJobCatCount>)=>{
                    if(res.status){
                        this.catCountFigures=res.response;
                        this.catCountFigures.isFresh=false;

                        //clear other parameters in order to use only catCountFigures
                        this.clearUnusedCatCountModalParameters();
                        this.changeDetector.markForCheck();
                    }
                },
                (error:HttpErrorResponse)=>{
                    console.log(error);
                }
            );
        }
    }

    showCatCountAnalysisData(){
        //if we have stored cat count figures skip the uploading part
        if(this.catCountFigures?.id){
            this.clearUnusedCatCountModalParameters();
            //display modal with form cat count figures and fill the stored values
            this.catCountAnalysisModal.catCountFigures=this.catCountFigures;

            this.catCountAnalysisModal.show();
            this.changeDetector.markForCheck();
        }
        else{
            this.catCountAnalyseModal.show();
            this.changeDetector.markForCheck();
        }
    }

    /**
     * @description clear other input parameters and leave only catCountFigures to affect cat counts
     */
    clearUnusedCatCountModalParameters(){
        this.catCountAnalysisModal.analysisResult=null;
        this.catCountAnalysisModal.resourceId=null;
        this.catCountAnalysisModal.clientId=null;
    }

    buildPostCatCountModel(e:any):PostQuoteJobCatCount{
        if(e){
            var rObj:PostQuoteJobCatCount=<PostQuoteJobCatCount>{
                insertUserId:this.globalConfiguration.currentUser.id,
                catCountFor:(e.discounts.catCountFor)?e.discounts.catCountFor:'',
                xtranslatedPerc:e.discounts.xtranslatedPerc,
                repetitionsPerc:e.discounts.repetitionsPerc,
                match100Perc:e.discounts.match100Perc,
                match9599Perc:e.discounts.match9599Perc,
                match8594Perc:e.discounts.match8594Perc,
                match7584Perc:e.discounts.match7584Perc,
                match5074Perc:e.discounts.match5074Perc,
                noMatchPerc:e.discounts.noMatchPerc,
                crossFileRepetitionsPerc:e.discounts.crossFileRepetitionsPerc,
                match101Perc:e.discounts.match101Perc,
                contextExact:e.discounts.contextMatch,
                quoteJobId:this._quoteJob.id,
                catToolId:this.catCountFigures.catToolId,
                totalWordsCount:e.totalWordCount,
                totalDiscountedWords:e.totalDiscounted,
                xtranslatedDiscount:e.discounts.xtranslatedDiscount,
                repetitionsDiscount:e.discounts.repetitionsDiscount,
                match100Discount:e.discounts.match100Discount,
                match101Discount:e.discounts.match101Discount,
                match9599Discount:e.discounts.match9599Discount,
                match5074Discount:e.discounts.match5074Discount,
                match7584Discount:e.discounts.match7584Discount,
                noMatchDiscount:e.discounts.noMatchDiscount,
                crossFileRepetitionsDiscount:e.discounts.crossFileRepetitionsDiscount,
                contextExactDiscount:e.discounts.contextMatchDiscount,
                match8594Discount:e.discounts.match8594Discount
            };

            return rObj;
        }
    }

    /***
     * @function rateTypeChanged
     * @description gets triggered when the rate type gets changed
     * @returns void
     */
    rateTypeChanged(e:number){
        if(e){
            this.enableControlsBasedOnCostType(e);
        }        
    }

    /***
     * @function unitChanged
     * @description gets triggered when the unit gets changed
     * @returns void
     */
    unitChanged(e:number){
        if(e){
            if (this.quoteJobs?.length > 0) {
                this.quoteJobs.map(job => {
                    job.unitId = e;
                    this.checkConvertRate(job, JobPriceDirection.custom);
                    this.checkPriceList(job);                    
                    return job;
                });
            } else {
                this.checkConvertRate(this.quoteJob, JobPriceDirection.custom);
                this.checkPriceList({sourceLanguageId: null, targetLanguageId: null, unitId: e });
            }
        }        
    }
}