/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/quotes */
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http'
import {Inject, Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { BaseResultModel, CategoryModel, SearchCriteriaModel, SearchResult } from 'app/models'
import {EntityStatusModel} from 'app/models/general';
import {ResponseCompany, ResponseCompanyBranch} from 'app/models/general/company-setting.model';
import { DropDownItem, DropdownItemModel } from 'app/models/general/dropdownItem.model'
import {AppConfigService} from 'app/services/app.settings.service';
import {Constants} from 'app/shared.constants';
import {StorageService} from 'ngx-webstorage-service';
import {Observable, of} from 'rxjs';
import {map, shareReplay, switchMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {CORE_SESSION_STORAGE} from '../storage/tp-storage.service.ts';
import {ApirequestService, HttpVerb} from './api-service.service';
import {StatusTypes} from "../../utills";
import {ResponseCompanyPriceTableData} from "../../models/response-models/response-company-price-table-data";
import {ResponseCatToolAnalysisResult} from 'app/main/admin/cat-count/models/cat-tool-analysis';
import { RequestsSearchCriteria } from '../../main/client/requests/models/requests-search-criteria'
import { ResponseRequestGridData } from '../../main/client/requests/models/response-request-grid-data'
import { HttpContextSkippingSpinner } from '../auth/custom-http-interceptor.service'

@Injectable({
    providedIn: 'root',
})
export class RefDataService {

    private endpoint = '/system/';
    private _uri = environment.apiUrl;

    constructor(
        private http: HttpClient,
                private configService: AppConfigService,
                private translate: TranslateService,
                @Inject(CORE_SESSION_STORAGE)
                private sessionStorage: StorageService<string>,
                private api:ApirequestService) {}    

    getCurrencies(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'currencies/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getGoogleSignInClientIdByDomainUrl(dUrl:string): Observable<BaseResultModel<string>> {
        return this.api.ApiRequest<BaseResultModel<string>>(environment.apiUrl + this.endpoint + 'google/signin',dUrl,new HttpHeaders(),HttpVerb.post,'json');
    }

    getCurrencyCodes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'currencyCodes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getLanguagesSupported(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'languages/2/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getUnits(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'unit/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getSoftwares(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'softwares/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getInvoiceStatuses(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'invoiceStatus/'+status+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getInvoiceTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'invoiceTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getUnitsByCategory(category: string): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'unit/category/' + category + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getFinancialOrganizationsByCategory(category: string): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'financialinstitutes/category/' + category + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getFinancialOrganizationsByStatus(statusId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'financialinstitutes/status/' + statusId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getHtmlTemplatesByStatus(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'htmlTemplates/status/ '+ status +' /dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getHtmlTemplatesByCategory(category:string): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'htmlTemplates/category/' + category + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getPaymentTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'paymentTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getRateTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'rateTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getPaymentMethods(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'paymentMethods/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getServices(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'service/status/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }
    getServiceTypesDropdown(status: StatusTypes = StatusTypes.all):Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'serviceType/'+status+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getServicesByParent(parentServiceId:number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'service/parent/' + parentServiceId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getResourceTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'resourceTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getResourceTeams(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/resource/resourceTeam/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getRefreshedServices(): Observable<BaseResultModel<DropDownItem[]>> {
       return this.getServices();
    }

    getCategories(): Observable<CategoryModel[]> {
        const persistedData = this.sessionStorage.get(
            Constants.CATEGORIES_STORAGE_KEY,
        );
        if (persistedData) {
            return of(<CategoryModel[]>JSON.parse(persistedData));
        } else {
            return of([] as CategoryModel[]);
        }
    }

    refreshCategories(): Observable<any> {
        return this.http
            .get<CategoryModel[]>(
                `${this._uri}${this.endpoint}/refCategories`,
            )
            .pipe(shareReplay(1))
            .pipe(
                switchMap((result) => {
                    this.sessionStorage.set(
                        Constants.CATEGORIES_STORAGE_KEY,
                        JSON.stringify(result),
                    );
                    return of();
                }),
            );
    }

    getIsWorkflowStepServices(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>
        (environment.apiUrl + this.endpoint + 'service/GetIsWorkflowStepServicesAsync',
            '',new HttpHeaders(),HttpVerb.get,'json');
    }

    getDropdownServicesWorkflowStepsByServiceId(serviceId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + `service/workflow-steps/dropdown/${serviceId}/`,'',new HttpHeaders(),HttpVerb.get,'json');
    }
    getAbsenceTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'absence-types/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getAbsenceTypesThatCount(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'absence-types/count-as-absence/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getTagCategories(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'tagCategory/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getTagByCategory(category: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'tagCategory/status/1/category/' + category.toString() + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getTagByCategoryIds(categoryIds: number[]): Observable<BaseResultModel<DropDownItem[]>> {
        return this.http.get<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'tagCategory/status/1/category/dropdown', {params: new HttpParams({fromObject:{'tagCategoryTypeIds': categoryIds}})});
    }

    getLeadPriorities(): Observable<CategoryModel[]> {
        return this.getCategories().pipe(
            map((categories) => {
                return categories.filter(category => category.categoryFor.includes(environment.categories.leadPriority));
            }),
        );
    }

    getCatTools(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'cat-tools/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
        
    }
    
    getProjectStatuses(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/project/projectStatus/'+status+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    } 

    getPOStatus(id): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/system/poStatus/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    updPOStatus(model:any): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/system/poStatus/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    addNewPOStatus(model:any): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/system/poStatus/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    getInvoiceStatus(id): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/system/invoiceStatus/id/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    updInvoiceStatus(model:any): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/system/invoiceStatus/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    addNewInvoiceStatus(model:any): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + '/system/invoiceStatus/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }
    
    getProjectTemplates(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/project/projectTemplate/status/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getProjects(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/project/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');        
    }
   
    refreshProjectStatuses(): Observable<void> {
        return this.http
            .get<EntityStatusModel[]>(
                `${this._uri}${this.endpoint}/PROJECT/entityStatuses`,
            )
            .pipe(
                switchMap((result): Observable<void> => {
                    this.sessionStorage.set(
                        Constants.PROJECT_STATUS_STORAGE_KEY,
                        JSON.stringify(result),
                    );
                    return of();
                }),
            );
    }

    getCompanyBranchSetting(branchId:number): Observable<BaseResultModel<ResponseCompanyBranch>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCompanyBranch>>(environment.apiUrl + this.endpoint + 'companyBranch/' + branchId,'',new HttpHeaders(),HttpVerb.get,'json');   
    }

    getCompanyPriceList(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<ResponseCompanyPriceTableData[]>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCompanyPriceTableData[]>>(environment.apiUrl + this.endpoint + 'companyPriceTable/status/' + statusId,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getCurrentUserCompanyBranchSetting(): Observable<BaseResultModel<ResponseCompanyBranch>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCompanyBranch>>(environment.apiUrl + '/company/companyBranch/current-user','',new HttpHeaders(),HttpVerb.get,'json');   
    }

    getCompanyBranches(statusId: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'companyBranch/' + statusId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getCompanySetting(companyId:number): Observable<BaseResultModel<ResponseCompany>> {
        return this.api.ApiRequest<BaseResultModel<ResponseCompany>>(environment.apiUrl + this.endpoint + 'company/' + companyId,'',new HttpHeaders(),HttpVerb.get,'json');   
    }

    getDateFormats(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'dateFormatTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');   
    }

    getTimeZones(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'timezoneOffsets/1/dropdown', '', new HttpHeaders(), HttpVerb.get, 'json');
    }

    getTimeList(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'timeTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');   
    }

    refreshCompanySetting(): void {
        this.getCurrentUserCompanyBranchSetting();
    }

    getQuoteStatuses(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/quote/quoteStatus/'+status+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getQuoteJobStatuses(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/quoteJob/quoteJobStatus/'+status+'/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getQuoteChangeStatusReasons(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'change-status-reasons/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getAbsenceRequestStatuses(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'absence-request-status/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json'); 
    }

    getServiceLanguages(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'languages/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }
    
    getCountries(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'countries/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    } 

    getAddressTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'addressTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    } 

    getGenders(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'genders/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    } 

    getContactTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'contactTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    } 

    getDepartments(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'companyDepartment/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    } 

    getDepartmentsByCompanyBranchId(companyBranchId:number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'companyDepartment/branch/' + companyBranchId + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }
    
    getClientTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'clientTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getLeads(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'clientTypes/2/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientsAndLeads(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/client/status/2/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientContacts(clientId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + `/client/contacts/${clientId}/dropdown`,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getProjectClientJobs(projectId: number): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + `/clientJob/1/dropdown/${projectId}`,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientResourceJobs(clientJobIds: number[]): Observable<any> {
        return this.http.get<any>(environment.apiUrl + `/resourceJob/1/dropdown`, {
            params: {
                clientJobIds: clientJobIds.join()
            }
        })
    }
    
    getPersonTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'personTypes/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getClientStatuses(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'clientStatus/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getAllClientStatuses(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + '/client/clientStatus/2/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getJobStatuses(status: StatusTypes = StatusTypes.active): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'jobStatus/' + status + '/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getActiveAndCompletedJobStatuses(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'jobStatus/2/dropdown/completed','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getReminderTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'reminder-types/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getSalesProfitReportTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + '/salesProfitReportsType/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getGoogleTranslateLanguages(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'googleTranslate/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    getGoogleTranslatedText(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + 'googleTranslate/api',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    addStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + 'jobStatus/add',model,new HttpHeaders(),HttpVerb.post,'json');
    }

    updateStatus(model): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + 'jobStatus/update',model,new HttpHeaders(),HttpVerb.put,'json');
    }

    getJobStatus(id): Observable<BaseResultModel<any>> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + 'jobStatus/' + id,'',new HttpHeaders(),HttpVerb.get,'json');
    }

    deleteJobStatusById(id: string): Observable<any> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + 'jobStatus/' + id,'',new HttpHeaders(),HttpVerb.delete,'json');
    }

    deleteInvoiceStatusById(id: string): Observable<any> {
        return this.api.ApiRequest<BaseResultModel<any>>(environment.apiUrl + this.endpoint + 'invoiceStatus/' + id,'',new HttpHeaders(),HttpVerb.delete,'json');
    }

    /**
     * 
     * @returns returns registered mimetypes to match them against uploaded files
     */
    getMimeTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'mime-types/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    /**
     *@function
     * @description get integration types
     * @returns Observable<BaseResultModel<DropDownItem[]>>
     */
    getIntegrationTypes(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'integration-types/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    /**
     *@function
     * @description determines if a company has IDP integration enabled
     * @returns Observable<BaseResultModel<boolean>>
     */
     isCompanyIDPIntegrated(): Observable<BaseResultModel<boolean>> {
        const options = {context: new HttpContext().set(HttpContextSkippingSpinner, true)};
        return this.http.get<BaseResultModel<boolean>>(environment.authApiUrl +'/sso-status', options);
    }

    /**
     * @function
     * @description get portals
     * @returns Observable<BaseResultModel<DropDownItem[]>>
     */
     getPortals(): Observable<BaseResultModel<DropDownItem[]>> {
        return this.api.ApiRequest<BaseResultModel<DropDownItem[]>>(environment.apiUrl + this.endpoint + 'portals/1/dropdown','',new HttpHeaders(),HttpVerb.get,'json');
    }

    /**
     * @description uploads a cat tool gnerated csv file for analysis report
     * @param formData 
     * @returns BaseResultModel<ResponseCatToolAnalysisResult>
     */
    uploadCatToolCsv(formData: FormData): Observable<BaseResultModel<ResponseCatToolAnalysisResult>> {
        return this.http.post<BaseResultModel<ResponseCatToolAnalysisResult>>(environment.apiUrl + this.endpoint + 'cat-tool-analysis/csv', formData);
    }

    /**
     * @function
     * @description determines if the particular company has client request flow enabled
     * @returns Observable<BaseResultModel<boolean>>
     */
    isCompanyClientRequestFlowEnabled(): Observable<BaseResultModel<boolean>> {
        const options = {context: new HttpContext().set(HttpContextSkippingSpinner, true)};
        return this.http.get<BaseResultModel<boolean>>(environment.apiUrl + '/company/client-request-flow', options);
    }
}