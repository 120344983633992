import { defaultEnvironment } from 'environments/environment.default';

export const environment = {
    production: true,
    hmr: false,
    commonApiUrl:'',
    domainUrl:'',
    apiUrl: 'https://sa.translationprojex.net/api',
    authUrl: 'https://sa.translationprojex.net/api/auth',
    extAuthUrl: 'https://sa.translationprojex.net/api/auth/external-provider',
    authApiUrl: 'https://api-auth.translationprojex.net/api/auth',
    uobDomain:'ub.edu',
    ...defaultEnvironment
};
