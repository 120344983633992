import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router, RouterEvent,} from '@angular/router';
import { LayoutService } from 'app/layout/layout.service';
import { TpAuthService } from 'app/services/auth/tp-auth.service';
import { UserService } from 'app/services/general/user.service';
import { NotificationService } from 'app/services/notification.service';
import { ReadThemeService } from 'app/services/theming/read-theme.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DEFAULT_INTERRUPTSOURCES, Idle} from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { TpModalComponent } from './components/tp-modal/tp-modal.component';
import { TPStorageService } from './services/storage/tp-storage.service.ts';
import {NavigationService} from "./services";
import {LocalizationService} from "./services/localization/localization.service";
import {StorageKeys} from "./utills/enums/storage-keys";
import {ThemeService} from "./services/theming/theme.service";
@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    @ViewChild(TpModalComponent) 
    timeout: TpModalComponent;
    readonly appSpinnerName = 'tpSpinner';
    idleState : any = 'Not started.';
    lastPing?: Date = null;
    idleModalShown = false;
    counterSecs = 0;
    private _unsubscribeAll:Subject<void> = new Subject<void>();
    constructor(
        @Inject(DOCUMENT) private document: Document,
        public viewContainerRef: ViewContainerRef, // Required by tp-color-picker -> ngx-color-picker
        private _platform: Platform,
        private _router: Router,
        public _authService: TpAuthService,
        private readThemeService: ReadThemeService,
        private layoutService: LayoutService,
        notificationService: NotificationService,
        private userService: UserService,
        private idle: Idle,
        private keepalive: Keepalive,
        private storageManager:TPStorageService,
        private navigationService: NavigationService,
        private localizationService: LocalizationService,
        private themeService: ThemeService
    ) {
        this.navigationService.subscribeRouterEvents();
        readThemeService.setupPeriodicRefresh();
        this._router.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(this.navigationInterceptor.bind(this));
        this.layoutService.init();
        this.localizationService.init();
        this.themeService.init();
        if (this._platform.ANDROID || this._platform.IOS) {
            this.document.body.classList.add('is-mobile');
        }
        notificationService.setupSpinner(this.appSpinnerName);
        // this.initIdle();
        this.redirect();
    }
    ngOnInit(): void {
        this.determineNavigation(); 
        
        //TODO: temporary fix for stack_overflow browser exception
        document.addEventListener('DOMNodeInserted', function () {
            const elements = document.querySelectorAll('[aria-owns]');

            elements.forEach(element => {
                element.removeAttribute('aria-owns');
            });
        });
    }
    ngOnDestroy(): void {
        this.readThemeService.teardown();
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
    initIdle(): void {
        this.idle.setIdle(600);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.idle.onIdleStart.subscribe(() => {
            if(!this.idleModalShown){
                this.timeout.show();
                this.idleModalShown = true;
            }
        });
        this.keepalive.interval(15);
        this.keepalive.onPing.subscribe(() => this.lastPing = new Date());
        this.reset();
    }
    /**
     * @function reset
     * @description reset, reset idl time out
     * @return void
     * */
    reset() {
        this.idle.watch();
        this.idleModalShown = false;
        this.timeout?.hide();
        this.counterSecs = 60;
        this.idleState = 60;
    }
    /**
     * @function stay
     * @description stay, reset idl time out
     * @return void
     * */
    stay() {
        this.reset();
    }
    /**
     * @function logoutIdleTimeOut
     * @description logout idl time out
     * @return void
     * */
    logoutIdleTimeOut(): void {
        this.reset();
        this._authService.logout();
    }
    /**
     * @function redirect
     * @description redirect, add to session storage current path and update every navigation end
     * @return void
     * */
    redirect(): void {
        const currentPath = window.location.pathname;
        const currentParams = window.location.search;
        if(currentPath !== '/') this.storageManager.write(StorageKeys.Redirect, (currentPath + currentParams));
        this._router.events.subscribe((e) => {
            if (e instanceof NavigationEnd) {
                if(e.url != '/' && e.url != '/login' && e.url){
                    this.storageManager.write(StorageKeys.Redirect, e.url);
                }
            }
        });
    }
    /**
     * @function determineNavigation
     * @description determine if a user is logged in, navigate to required page after fetching user's data
     * @return void
     * */
    determineNavigation(): void {
        if(this._authService.hasAccessToken()) {
            this.userService.loadUser();
        } else {
            let storedPath = this.storageManager.read(StorageKeys.Redirect) as string;
            if(storedPath) {
                if (!storedPath.startsWith('/quote-download/') && 
                    !storedPath.startsWith('/resource-job-acceptance/') && 
                    !storedPath.startsWith('/forgot-password') && 
                    !storedPath.startsWith('/reset-password') &&
                    !storedPath.startsWith('/preview') &&
                    !storedPath.startsWith('/sign-in')) {
                    this._authService.logout();
                    this._router.navigateByUrl('/login').then();
                }
            } else {
                this._authService.logout();
                this._router.navigateByUrl('/login').then();
            }
        }
    }
    /**
     * @function navigationInterceptor
     * @description navigation interceptor
     * @return void
     * */
    private navigationInterceptor(e: RouterEvent) {
        if (e instanceof NavigationStart) {
            document.body.classList.add('app-loading');
        }
        if (e instanceof NavigationEnd) {
            this.readThemeService.scrollTop(0, 0);
        }
        if (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) {
            if (this.layoutService.isSmallScreen() && !this.layoutService.isCollapsed()) {
                setTimeout(() => this.layoutService.setCollapsed(true, true), 10);
            }
            document.body.classList.remove('app-loading');
        }
    }
}